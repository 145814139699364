import React from "react"

const DisplayOnlyPage = ({pages}) => {
  return pages.map(({ node }) => {
    return (
      <div key={node.fields.slug}>
        <article className="wrap">
          <section dangerouslySetInnerHTML={{ __html: node.html }} />
          <footer>
          </footer>
        </article>
      </div>
    )
  })
}

export default DisplayOnlyPage;
